import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { SectionTitle } from "../components/Section-Title"
import { Colors } from "../colors/Colors"
import header from "../images/woodbrook.jpg"
import PageHeader from "../components/page-header"
import { FaDirections, FaWhatsapp } from "react-icons/fa"
import SEO from "../components/seo"

const MainContainer = styled.div`
  padding: 50px 20px;
`
const LocationsContainer = styled.div`
  max-width: 800px;
  margin: 50px auto;
`
const LocationBlock = styled.div`
  margin: 0 0 80px 0;
`
const Title = styled.h2`
  font-family: "Articulat";
  font-size: 21px;
  text-align: center;
  margin-bottom: 30px;
`
const Heading = styled.h4`
  font-family: "Articulat";
  font-size: 16px;
  color: ${Colors.gold};
  font-weight: 700;
  margin: 0 0 10px 0;
`
const Copy = styled.p`
  margin: 0;
  font-family: "Articulat";
  font-size: 16px;

  @media (max-width: 550px) {
    font-size: 18px;
  }

  span {
    font-weight: bold;
  }
`
const InfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 20px;
  justify-content: space-between;
  margin: 0 0 20px 0;

  @media (max-width: 550px) {
    flex-direction: column;
    text-align: center;
  }
`
const Block = styled.div`
  flex: 1;

  @media (max-width: 550px) {
    margin-bottom: 20px;
  }
`
const DirectionsLink = styled.a`
  text-decoration: underline;
  color: ${Colors.gold};
  font-family: "Articulat";
`
const TelephoneLink = styled.a`
  text-decoration: none;
  color: inherit;

  :hover {
    text-decoration: underline;
  }
`

const LocationsPage = props => (
  <Layout>
    <SEO title="Locations" />
    <PageHeader noLogo image={header} />
    <MainContainer>
      <SectionTitle>Smile Inn Locations</SectionTitle>

      <LocationsContainer>
        <LocationBlock>
          <Title>St James Location</Title>
          <InfoBlock>
            <Block>
              <Heading>Address</Heading>
              <Copy>
                24 Mucurapo Road,
                <br />
                St James,
                <br />
                Trinidad & Tobago
              </Copy>
            </Block>
            <Block>
              <Heading>Hours</Heading>
              <Copy>
                Mon-Thur: <span>7AM - 3PM</span>
                <br />
                Fri: <span>7AM - 12PM</span>
                <br />
                Sat: <span>7AM - 2PM</span>
              </Copy>
            </Block>
            <Block>
              <Heading>Phone</Heading>
              <Copy>
                <TelephoneLink href="tel:868-472-8666">
                  (868) 472-8666
                </TelephoneLink>
                <br />
                <TelephoneLink href="tel:868-689-0077">
                  (868)-689-0077
                </TelephoneLink>
                <br />
                <TelephoneLink href="tel:868-241-3688">
                  (868)-241-3688
                </TelephoneLink>
              </Copy>
            </Block>
          </InfoBlock>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DirectionsLink
              target="_blank"
              href="https://g.page/dr-shenilee-hazell?share"
            >
              Click for directions <FaDirections />
            </DirectionsLink>
          </div>
          <hr style={{ opacity: 0.3, margin: "50px 0" }} />
        </LocationBlock>
      </LocationsContainer>
    </MainContainer>
  </Layout>
)

export default LocationsPage
